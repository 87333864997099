<template>
    <v-card rounded="lg" class="rounded-lg pa-3 pt-0">
        <v-card-title class="px-3 pb-2 pt-4">
            <div class="headingConf">
                <h2 class="mb-0 mr-6">{{$t('Configuration')}}</h2>
            </div>
            <div class="iconEdit" v-if="this.$func.hasRight('retroplanning/update')">
                <v-btn :disabled="!['DRAFT', 'PROVIDER_REFUSAL', 'ON_GOING'].includes(campaign.status)" text depressed class="square" @click="$emit('edit')">
                    <v-icon small>$pencil_l</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-card-text class="px-3 pt-1">
            <div class="configurationContent">
                <div class="block text-center">
                    <div class="pb-1 containerConf" v-if="campaign.name || (campaign.config && (campaign.config.canal || campaign.config.date_start || campaign.config.date_end))">
                        <div class="configContainer" v-if="campaign.reference">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('campaignReference')}} :</b> {{campaign.reference}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.name">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('campaignName')}} :</b> {{campaign.name}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.canal">
                            <div  class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('campaignType')}} :</b> {{ $t(campaign.config.canal.toLowerCase()) }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.date_start">
                            <div  class="configLabel text-left" v-if="campaign.status == 'DRAFT'">
                                <p class="mb-0 mr-2"><b>{{$t('campaignSendingDate')}} :</b>
                                     {{ parseFullDateToFr(campaign.config.date_start) }} 
                                    <span class="warning--text" v-if="campaign.config && campaign.config.prestataire && campaign.config.prestataire.showLimitDateProvider">
                                        ({{campaign.config.prestataire.showLimitDateProvider}} {{$t('days_delay_for_provider')}})
                                    </span>
                                </p>
                            </div>
                            <div  class="configLabel text-left" v-else>
                                <p class="mb-0 mr-2" v-if="new Date(campaign.programmed_date) > new Date(campaign.config.date_start)"><b>{{$t('campaignSendingDate')}} :</b>
                                     {{ parseFullDateToFr(campaign.programmed_date) }} 
                                    <span class="warning--text" v-if="campaign.config && campaign.config.prestataire && campaign.config.prestataire.showLimitDateProvider">
                                        ({{campaign.config.prestataire.showLimitDateProvider}} {{$t('days_delay_for_provider')}})
                                    </span>
                                </p>
                                <p class="mb-0 mr-2" v-else><b>{{$t('campaignSendingDate')}} :</b>
                                     {{ parseFullDateToFr(campaign.config.date_start) }} 
                                    <span class="warning--text" v-if="campaign.config && campaign.config.prestataire && campaign.config.prestataire.showLimitDateProvider">
                                        ({{campaign.config.prestataire.showLimitDateProvider}} {{$t('days_delay_for_provider')}})
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.spread">
                            <div  class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('campaignSpread')}} :</b> {{ $t('spread_configuration_label', { nb: campaign.config.nb_packages, hours:campaign.config.nb_hours }) }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-else>
                            <div  class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('campaignSpread')}} :</b> {{ $t('no') }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.spread">
                            <div  class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('previsionnal_enddate')}} :</b> {{ getCampaignSpreadEndDate }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.date_end">
                            <div  class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{$t('end')}} :</b> {{ parseFullDateToFr(campaign.config.date_end) }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.operation_id">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2">
                                    <b>{{ $t('operation') }} :</b>&nbsp;
                                    <router-link v-if="this.$func.hasRight('operation/get')" :to="{name: 'OperationDetail', params: { operation_id: campaign.operation_id.id }}">{{ campaign.operation_id.label }}</router-link>
                                    <span v-else>{{ campaign.operation_id.label }}</span>
                                </p>
                            </div>
                        </div>
                        <!-- <div class="configContainer" v-if="campaign.config && campaign.config.prestataire_type && campaign.config.prestataire_type === 'VENDORS'">
                            <div class="configLabel text-left">
                                <p class="mb-0"><b>{{ $t('prestataire') }} :</b> {{ $t('vendors') }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.prestataire">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('prestataire') }} :</b> {{ campaign.config.prestataire.text }}</p>
                            </div>
                        </div> -->
                        <div class="configContainer" v-if="campaign.config && campaign.config.main_contact && (campaign.config.type === 'MISSION' || (campaign.config.type === 'CAMP_CALL' && campaign.config.prestataire_type === 'CALL'))">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('main_contact') }} :</b> {{ campaign.config.main_contact.text }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.infomediaire">
                            <div  class="configLabel text-right"><p class="mb-0 mr-2"><b>{{ $t('infomediaire') }} :</b></p></div>
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('infomediaire') }} :</b> {{ campaign.config.infomediaire.text }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.entrypointsLanding">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('entrypointsLanding') }} :</b> <a target="_blank" :href="'https://'+arrayOfObjectToString(campaign.config.entrypointsLanding, 'text')">{{arrayOfObjectToString(campaign.config.entrypointsLanding, 'text')}}</a></p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.entrypointsEmail">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('entrypointsEmail') }} :</b> {{arrayOfObjectToString(campaign.config.entrypointsEmail, 'text')}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.entrypointsCallIn">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('entrypointsCallIn') }} :</b> {{arrayOfObjectToString(campaign.config.entrypointsCallIn, 'text')}}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.segmentation">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('segmentation') }} :</b> {{ campaign.config.segmentation.text }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.prevision_limit && campaign.config.sending_vol">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('limited_sending_number') }} :</b> {{ campaign.config.sending_vol }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.template_cat">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('template_cat') }} :</b> {{ campaign.config.template_cat.text }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.config && campaign.config.objective">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('retroplanningconfig.objectivetitle') }} :</b> {{ campaign.config.objective }}</p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.programmed_by">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('programmedBy') }} : </b>
                                    <router-link v-if="!campaign.programmed_by.trash && this.$func.hasRight('user/get')" :to="{ name: 'UserDetail', params: { user_id: campaign.programmed_by.id }}"><b class="link">{{ campaign.programmed_by.firstname }} {{ campaign.programmed_by.name }}</b></router-link>
                                    <v-tooltip v-else-if="campaign.programmed_by.trash" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">
                                                {{ campaign.programmed_by.firstname }} {{ campaign.programmed_by.name }}
                                            </span>
                                        </template>
                                        <span>{{ $t('userDeleted') }}</span>
                                    </v-tooltip>
                                    <b v-else>{{ campaign.programmed_by.firstname }} {{ campaign.programmed_by.name }}</b>
                                    <template v-if="campaign.programmed_date">
                                        {{$t('the')}} {{parseFullDateToFr(campaign.programmed_date) }}
                                    </template>
                                </p>
                            </div>
                        </div>
                        <div class="configContainer" v-if="campaign.diabolo_campaign && campaign.diabolo_campaign.text">
                            <div class="configLabel text-left">
                                <p class="mb-0 mr-2"><b>{{ $t('diaboloCampaign') }} :</b> {{ campaign.diabolo_campaign.text }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import dateManipulation from '@/mixins/dateManipulation'
export default {
    name: "CampaignConfigurationComponent",
    mixins:[dateManipulation],
    props:['campaign'],
    data() {
        return {
            entities:[]
        }
    },
     created() {
        this.entities = []
        // this.getEntities()
    },
    mounted() {
        this.entities = []
        // this.getEntities()
    },
    watch: {
        campaign(newCampaign) {
            if(this.campaign.status && this.campaign.status === 'DRAFT' && this.campaign.config && this.campaign.config.date_start && this.campaign.config.prestataire && this.campaign.config.prestataire.deadline_mission) {
                let currentDate = new Date();
                let dateLimitProvider = new Date(this.campaign.config.date_start);
                dateLimitProvider.setDate(dateLimitProvider.getDate() - this.campaign.config.prestataire.deadline_mission);

                if(currentDate > dateLimitProvider) {
                    this.campaign.config.prestataire.showLimitDateProvider = this.DaysBetween(dateLimitProvider, currentDate);
                }
            }
        }
    },
    computed: {
        getCampaignSpreadEndDate() {
            if(this.campaign && this.campaign.status == "DRAFT") {
                if(this.campaign.config && this.campaign.config.date_start && new Date(this.campaign.config.date_start) < new Date()) {
                    let date = new Date();
                    date.setHours(date.getHours() + ((this.campaign.config.nb_packages - 1) * this.campaign.config.nb_hours));
                    return this.parseFullDateToFr(this.parseFullJsDateToUs(date));
                } else {
                    let date = new Date(this.campaign.config.date_start);
                    date.setHours(date.getHours() + ((this.campaign.config.nb_packages - 1) * this.campaign.config.nb_hours));
                    return this.parseFullDateToFr(this.parseFullJsDateToUs(date));
                }
            } else {
                if(this.campaign.config && this.campaign.programmed_date && this.campaign.config.spread && this.campaign.config.nb_packages && this.campaign.config.nb_hours) {
                    let date = new Date(this.campaign.programmed_date);
                    date.setHours(date.getHours() + ((this.campaign.config.nb_packages - 1) * this.campaign.config.nb_hours));
                    return this.parseFullDateToFr(this.parseFullJsDateToUs(date));
                }
            }
        }
    },
    methods: {
        
        getEntities() {
            if(this.campaign && this.campaign.entity_id){
                // Remarque => entity_id n'est pas un tableau
                // this.campaign.entity_id.forEach(entityId => {
                //     GenericDataService.getData('/entity/'+entityId+'/get').then((response) => {
                //         let findEntity = this.entities.find((e) => e.id == response.data.data.id)
                //         if(!findEntity){
                //             this.entities.push(response.data.data)
                //         }
                //     })
                // });
            }
        }, 
        arrayOfObjectToString(array, field) {
            var string = array.map(function(item) {
                return item[field];
            });

            return string.join(', ');
        }
    },
}
</script>
<style lang="scss">
    .headingConf{
        display:flex;
        align-items: center;
        justify-content: flex-start;
        h2 {
            height:auto !important;
            min-height: auto !important;
        }
    }
    .containerConf{
        display:flex;
        flex-wrap: wrap;
    }
    .configContainer{
        width:33%;
    }
    .iconEdit {
        position:absolute;
        top:16px;
        right:24px;
    }
    .configurationContent {
        color:rgba(0,0,0,.87);
        //height:100%;
        //margin-top:30px;
        
        .attachementDiv  {
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }   
    }
</style>