<template>
    <v-card rounded="lg" class="mb-6" style="height:100%;">
        <v-card-title>
            <h2 v-if="isAcqui" class="mb-0 text-uppercase" style="width:100%; align-items:center;">{{$t(title)}}</h2>
            <h3 v-else class="black--text" style="text-transform:none;">{{$t(title)}}</h3>
        </v-card-title>
        <v-card-text>
            <DonutComponent :dataSet="graphData" :legend="false" style="height:200px;"/>
            <div class="legend mr-6">
                <div v-for="(legend, index) in (this.legends)" style="cursor:pointer" :key="'legend_'+index">
                    <span :style="'width: 20px; height:10px; display:inline-block; background-color:'+backgroundRefs[index]"></span> {{to_trad ? $t(legend) : legend}}
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import DonutComponent from '@/components/graphs/DonutComponent'

export default {
    name: 'campDonutComponent',
    props: ['data', 'title', 'to_trad', 'isAcqui'],
    components: {DonutComponent},
    data() {
        return {   
            graphData: {}, 
            backgroundRefs:["#FAEBD7","#00FFFF","#7FFFD4", "#F5F5DC","#FFE4C4","#000000","#FFEBCD","#0000FF","#8A2BE2","#A52A2A","#DEB887","#5F9EA0","#7FFF00","#D2691E","#FF7F50","#6495ED","#FFF8DC","#DC143C","#00FFFF","#00008B","#008B8B","#B8860B","#A9A9A9","#006400","#BDB76B","#8B008B","#556B2F","#FF8C00","#9932CC","#8B0000","#E9967A","#8FBC8F","#483D8B","#2F4F4F","#00CED1","#9400D3","#FF1493","#00BFFF","#696969","#1E90FF","#B22222","#FFFAF0","#228B22","#FF00FF","#DCDCDC","#F8F8FF","#FFD700","#DAA520","#ADFF2F","#F0FFF0","#FF69B4","#CD5C5C","#4B0082","#FFFFF0","#F0E68C","#E6E6FA","#FFF0F5","#7CFC00","#FFFACD","#ADD8E6","#F08080","#E0FFFF","#FAFAD2","#90EE90","#D3D3D3","#FFB6C1","#FFA07A","#20B2AA","#87CEFA","#778899","#B0C4DE","#FFFFE0","#00FF00","#32CD32","#FAF0E6","#FF00FF","#66CDAA","#0000CD","#BA55D3","#9370DB","#3CB371","#7B68EE","#00FA9A","#48D1CC","#C71585","#191970","#F5FFFA","#FFE4E1","#FFE4B5","#FFDEAD","#000080","#FDF5E6","#808000","#6B8E23","#FFA500","#FF4500","#DA70D6","#EEE8AA","#98FB98","#AFEEEE","#DB7093","#FFEFD5","#FFDAB9","#CD853F","#FFC0CB","#DDA0DD","#B0E0E6","#FF0000","#BC8F8F","#4169E1","#8B4513","#FA8072","#F4A460","#2E8B57","#FFF5EE","#A0522D","#C0C0C0","#87CEEB","#6A5ACD","#708090","#FFFAFA","#00FF7F","#4682B4","#D2B48C","#008080","#D8BFD8","#FF6347","#40E0D0","#EE82EE","#F5DEB3","#FFFFFF","#F5F5F5","#FFFF00","#9ACD32"],
            legends:{}
        }
    },
    methods: {
        
    },
    mounted() {
        this.graphDataFct();
    },
    created() {
        this.graphDataFct();
    },
    methods: {
        getObjectiveForm(){
            this.$emit('editCallStats');
        },
        graphDataFct(){
            let graphData = {}
            graphData.datasets = [];
            let dataset = {};

            let arrayData = [];
            for (var label in this.data) {
                arrayData.push([label, this.data[label]]);
            }
            arrayData.sort(function(a, b) {
                return b[1] - a[1];
            });

            let objSorted = {}
            arrayData.forEach(function(item){
                objSorted[item[0]]=item[1]
            })

            let isEmpty = false;
            if(this.data.length == 0){
                isEmpty = true;
            } else {
                let num = 0;
                for (var key in this.data) {
                    if (this.data.hasOwnProperty(key)) {
                        if(this.data[key] != 0){
                            num += this.data[key];
                        } 
                    }
                }
                if(num == 0){
                    isEmpty = true;
                }
            }


            this.legends = Object.keys(objSorted);
        
            let labels = Object.keys(objSorted);
            if(this.to_trad){
                labels = labels.map(label => this.$t(label));
            }

            dataset.label = "";          
            dataset.data = (isEmpty ? [100] : Object.values(objSorted));
            dataset.backgroundColor =  (isEmpty ? ['#DDD'] : this.backgroundRefs.slice(0, objSorted.length));
            graphData.datasets.push(dataset);
            graphData.labels = (graphData.total == 0 ? [this.$t('nodata')] : labels)
            //graphData.labels = (graphData.total == 0 ? [this.$t('nodata')] : this.labels);
            this.graphData = graphData;
        },
    },
}
</script>